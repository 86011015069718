.toastContainer_e70488 {
  pointer-events: none;
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.toast_e70488 {
  max-width: 295px;
  font: var(--font-body);
  color: var(--color-primary-button-text);
  border-radius: var(--radius);
  background: var(--color-toast);
  box-shadow: var(--shadow-2);
  text-align: center;
  word-break: break-word;
  pointer-events: auto;
  margin: 0 auto;
  padding: 8px 16px;
}

.ReactModal__Content[role="toast"] {
  opacity: 0;
  transition: opacity .3s ease-in-out;
}

.ReactModal__Content--after-open[role="toast"] {
  opacity: 1;
}

.ReactModal__Content--before-close[role="toast"] {
  opacity: 0;
}

body.desktop .toast_e70488 {
  border: 1px solid var(--color-toast-border);
  padding: 12px 16px;
}

.OXU_cq_button {
  height: 44px;
  border-radius: var(--radius);
  font: var(--font-body-bold);
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  transition: background .2s ease-in-out;
  display: flex;
}

.OXU_cq_large {
  width: 100%;
}

.OXU_cq_small {
  min-width: 50px;
}

.OXU_cq_primary {
  background: var(--color-primary);
  color: var(--color-primary-button-text);
  border: none;
}

.OXU_cq_secondary {
  border: 1px solid var(--color-border);
  color: var(--color-text);
  background: none;
}

.OXU_cq_secondary.OXU_cq_disabled {
  border-color: var(--color-border);
  color: var(--color-text-disabled);
}

.OXU_cq_outline {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
  background: none;
}

.OXU_cq_outline.OXU_cq_disabled {
  border-color: var(--color-border);
  color: var(--color-text-disabled);
}

.OXU_cq_outline:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

.OXU_cq_outline:active {
  background: var(--color-pressed-variant);
}

.OXU_cq_outline:not(:disabled):not(:active):hover {
  background: var(--color-hover-variant);
}

body.mobile .OXU_cq_primary.OXU_cq_disabled {
  background: var(--color-layer);
  color: var(--color-text-disabled);
}

body.desktop .OXU_cq_primary.OXU_cq_disabled {
  background: var(--color-inverse-on-surface);
  color: var(--color-text-disabled);
}

body.desktop .OXU_cq_primary:focus-visible {
  outline: 3px solid var(--color-focused-variant);
}

body.desktop .OXU_cq_primary:active {
  background: var(--color-primary-pressed);
}

body.desktop .OXU_cq_primary:not(:disabled):not(:active):hover {
  background: var(--color-primary-hover);
}

body.desktop .OXU_cq_secondary:focus-visible {
  outline: 3px solid var(--color-focused);
}

body.desktop .OXU_cq_secondary:active {
  background: var(--color-pressed);
}

body.desktop .OXU_cq_secondary:not(:disabled):not(:active):hover {
  background: var(--color-hover);
}

._6aRz6W_iconButton {
  border: none;
  border-color: none;
  cursor: pointer;
  background: none;
  border-radius: 6px;
  outline: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
  display: flex;
}

._6aRz6W_iconButton > svg {
  color: var(--color-icon);
}

body.desktop ._6aRz6W_iconButton:active {
  background: var(--color-pressed);
}

body.desktop ._6aRz6W_iconButton:hover:not(:active) {
  background: var(--color-hover);
}

.sIGcUW_overlay {
  background: var(--color-overlay);
  position: fixed;
  inset: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.IfS0XG_overlay {
  z-index: 100;
}

.IfS0XG_modal {
  width: 600px;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.IfS0XG_container {
  background: var(--color-dialogue);
  border: 1px solid var(--color-divider);
  border-radius: 16px;
  padding: 24px;
}

.IfS0XG_header {
  font: var(--font-header);
  color: var(--color-text);
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.IfS0XG_header svg {
  width: 24px;
  height: 24px;
}

.IfS0XG_content {
  font: var(--font-body);
  color: var(--color-text);
  margin-bottom: 24px;
}

.IfS0XG_footer {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.IfS0XG_footer > * {
  flex-shrink: 1;
}

.IfS0XG_footer > button:first-child {
  margin-right: 12px;
}

.fkIcSW_overlay {
  z-index: 100;
}

.fkIcSW_modal {
  outline: none;
  position: absolute;
  top: 50%;
  left: 20px;
  right: 20px;
  transform: translate(0, -50%);
}

.fkIcSW_container {
  background: var(--color-dialogue);
  border-radius: var(--radius);
  padding: 20px;
}

.fkIcSW_content {
  text-align: center;
  font: var(--font-body);
  color: var(--color-text);
}

.fkIcSW_footer {
  align-items: center;
  margin-top: 24px;
  display: flex;
}

.fkIcSW_footer > * {
  flex: 1;
}

.fkIcSW_footer > button:first-child {
  margin-right: 8px;
}

.fkIcSW_iframeModal {
  top: 40px;
  bottom: 40px;
  transform: none;
}

.fkIcSW_iframeModal .fkIcSW_container {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 0;
  display: flex;
}

.fkIcSW_iframeModal .fkIcSW_content {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 20px;
  display: flex;
}

.fkIcSW_iframeModal .fkIcSW_footer {
  border-top: 1px solid var(--color-divider);
  margin-top: 0;
  padding: 20px;
}

:root {
  --light-primary-color: #5d34f2;
  --light-focused-variant: #5d34f229;
  --light-hover-variant: #5d34f214;
  --light-pressed-variant: #5d34f21f;
  --light-hover: #7350f4;
  --light-pressed: #4718f0;
  --dark-primary-color: #7958ff;
  --dark-focused-variant: #7958ff29;
  --dark-hover-variant: #7958ff14;
  --dark-pressed-variant: #7958ff1f;
  --dark-hover: #957aff;
  --dark-pressed: #5d36ff;
}

body {
  background-color: var(--color-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;
}

._1lmpHW_container {
  background: var(--color-base);
  color: var(--color-text);
  font: var(--font-body);
  flex-direction: column;
  justify-content: normal;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
  overflow: auto;
}

._1lmpHW_content {
  background-color: var(--color-surface);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1lmpHW_placeHolder {
  flex: 1;
}

body.mobile {
  --color-primary-button-text: #fff;
  --color-toast: #34353f;
  --color-toast-border: #444748;
  --color-overlay: #0000004d;
  --color-loading-icon-light: #f7f8f8;
  --shadow-2: 0 4px 12px #0000001f;
  --font-title: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-body-bold: 500 16px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-body: 400 16px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-caption: 400 14px / 18px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --radius: 8px;
  --max-width: 360px;
}

body.mobile._1lmpHW_light {
  --color-base: #fff;
  --color-surface: #fff;
  --color-surface-variant: #e5e1ec;
  --color-text: #191c1d;
  --color-icon: #747778;
  --color-caption: #747778;
  --color-outline: #78767f;
  --color-divider: #e0e3e3;
  --color-text-disabled: #c4c7c7;
  --color-border: #c4c7c7;
  --color-primary: var(--light-primary-color);
  --color-layer: #eff1f1;
  --color-error: #ba1b1b;
  --color-dialogue: #fff;
  --color-loading-icon: #191c1d;
  --color-inverse-social-bg: #000;
}

body.mobile._1lmpHW_dark {
  --color-base: #191c1d;
  --color-surface: #191c1d;
  --color-surface-variant: #47464e;
  --color-text: #f7f8f8;
  --color-icon: #a9acac;
  --color-caption: #a9acac;
  --color-outline: #928f9a;
  --color-divider: #444748;
  --color-text-disabled: #5c5f60;
  --color-border: #5c5f60;
  --color-primary: var(--dark-primary-color);
  --color-layer: #2a2c32;
  --color-error: #dd3730;
  --color-dialogue: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-loading-icon: #f7f8f8;
  --color-inverse-social-bg: #fff;
}

body.mobile ._1lmpHW_content {
  flex: 1;
  align-self: stretch;
  padding: 16px 20px;
  position: relative;
}

body.desktop {
  --color-primary-button-text: #fff;
  --color-toast: #34353f;
  --color-toast-border: #444748;
  --color-overlay: #0000004d;
  --color-loading-icon-light: #f7f8f8;
  --shadow-1: 0 4px 8px #00000014;
  --shadow-2: 0 4px 12px #0000001f;
  --font-title: 600 32px / 40px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-title-medium: 600 28px / 36px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-title-small: 600 16px / 24px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-header: 600 20px / 28px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-body-bold: 500 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-body: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --font-caption: 400 14px / 20px -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  --radius: 8px;
  --max-width: 400px;
}

body.desktop._1lmpHW_light {
  --color-base: #e5e1ec;
  --color-surface: #fff;
  --color-surface-variant: #e5e1ec;
  --color-text: #191c1d;
  --color-text-disabled: #c4c7c7;
  --color-border: #c4c7c7;
  --color-border-variant: #c9c5d0;
  --color-caption: #747778;
  --color-icon: #747778;
  --color-focused: #191c1d29;
  --color-pressed: #191c1d1f;
  --color-hover: #191c1d14;
  --color-primary: var(--light-primary-color);
  --color-focused-variant: var(--light-focused-variant);
  --color-hover-variant: var(--light-hover-variant);
  --color-pressed-variant: var(--light-pressed-variant);
  --color-primary-pressed: var(--light-pressed);
  --color-primary-hover: var(--light-hover);
  --color-inverse-on-surface: #f3effa;
  --color-outline: #78767f;
  --color-layer: #eff1f1;
  --color-dialogue: #fff;
  --color-divider: #e0e3e3;
  --color-error: #ba1b1b;
  --color-loading-icon: #191c1d;
  --color-inverse-social-bg: #000;
  --color-inverse-social-hover: #000000eb;
  --color-inverse-social-pressed: #000000e0;
}

body.desktop._1lmpHW_dark {
  --color-base: #2a2c32;
  --color-surface: #191c1d;
  --color-surface-variant: #47464e;
  --color-text: #f7f8f8;
  --color-text-disabled: #5c5f60;
  --color-border: #5c5f60;
  --color-border-variant: #5f5d67;
  --color-caption: #a9acac;
  --color-icon: #a9acac;
  --color-focused: #f7f8f829;
  --color-pressed: #f7f8f81f;
  --color-hover: #f7f8f814;
  --color-primary: var(--dark-primary-color);
  --color-focused-variant: var(--dark-focused-variant);
  --color-hover-variant: var(--dark-hover-variant);
  --color-pressed-variant: var(--dark-pressed-variant);
  --color-primary-pressed: var(--dark-pressed);
  --color-primary-hover: var(--dark-hover);
  --color-inverse-on-surface: #2d3132;
  --color-outline: #928f9a;
  --color-layer: #2a2c32;
  --color-dialogue: linear-gradient(0deg, #cabeff14, #cabeff14), linear-gradient(0deg, #c4c7c705, #c4c7c705), #191c1d;
  --color-divider: #444748;
  --color-error: #dd3730;
  --color-loading-icon: #f7f8f8;
  --color-inverse-social-bg: #fff;
  --color-inverse-social-hover: #ffffffeb;
  --color-inverse-social-pressed: #ffffffe0;
}

body.desktop ._1lmpHW_content {
  width: 640px;
  min-height: 640px;
  box-shadow: var(--shadow-1);
  border-radius: 16px;
  padding: 24px;
  position: relative;
}

.EmK7ba_overlay {
  background-color: var(--color-overlay);
  z-index: 300;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.EmK7ba_loadingIcon {
  color: var(--color-loading-icon);
  animation: EmK7ba_rotating 1s steps(12, end) infinite;
}

.EmK7ba_container {
  width: 60px;
  height: 60px;
  border-radius: var(--radius);
  background-color: var(--color-toast);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.EmK7ba_container .EmK7ba_loadingIcon {
  color: var(--color-loading-icon-light);
}

@keyframes EmK7ba_rotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

body.desktop .EmK7ba_container {
  border: 1px solid var(--color-toast-border);
}

.keOsBq_preview, .keOsBq_preview main {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.CKtIva_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.CKtIva_connector {
  margin-bottom: 16px;
}

.CKtIva_connector > img {
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: center;
}

.CKtIva_message {
  text-align: center;
  font: var(--font-caption);
  color: var(--color-caption);
}

.C4P11G_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.C4P11G_connectorContainer {
  flex: 1;
}

.tkq4Sq_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.tkq4Sq_wrapper img {
  width: 96px;
  height: 96px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 16px;
}

.mD_jbq_navBar {
  width: 100%;
  min-height: 44px;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  display: flex;
  position: relative;
}

.mD_jbq_navBar > svg {
  fill: current-color;
}

.mD_jbq_navBar .mD_jbq_title {
  font: var(--font-body-bold);
}

.mD_jbq_backButton {
  color: --color-text;
  font: var(--font-body-bold);
  cursor: pointer;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
}

body.mobile .mD_jbq_backButton > span {
  display: none;
}

body.desktop .mD_jbq_backButton {
  color: var(--color-primary);
}

body.desktop .mD_jbq_backButton:hover {
  text-decoration: underline;
}

.-\37 GsHW_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.-\37 GsHW_wrapper .-\37 GsHW_container {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.-\37 GsHW_wrapper .-\37 GsHW_title {
  text-align: center;
}

.-\37 GsHW_wrapper .-\37 GsHW_message {
  font: var(--font-caption);
  color: var(--color-caption);
  text-align: center;
}

body.mobile .-\37 GsHW_title {
  font: var(--font-title);
  color: var(--color-text);
  margin-bottom: 16px;
}

body.mobile .-\37 GsHW_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 16px;
}

body.desktop .-\37 GsHW_title {
  font: var(--font-title);
  color: var(--color-text);
  font: var(--font-title-medium);
  margin-bottom: 8px;
}

body.desktop .-\37 GsHW_backButton {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto 48px;
}

.u6h5rG_error {
  font: var(--font-caption);
  color: var(--color-error);
}

.eHQ0hG_wrapper {
  border-radius: var(--radius);
  background: var(--color-layer);
  height: 44px;
  border: 1px solid #0000;
  align-items: center;
  transition-property: outline, border;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  display: flex;
  position: relative;
  overflow: hidden;
}

.eHQ0hG_wrapper .eHQ0hG_actionButton {
  color: var(--color-icon);
  width: 24px;
  height: 24px;
  display: none;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.eHQ0hG_wrapper input {
  caret-color: var(--color-primary);
  font: var(--font-body-bold);
  color: var(--color-text);
  background: none;
  flex: 1;
  align-self: stretch;
  padding: 0 16px;
}

.eHQ0hG_wrapper input::placeholder {
  color: var(--color-caption);
}

.eHQ0hG_wrapper input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-layer) inset;
  -webkit-text-fill-color: var(--color-text);
  transition: none;
}

.eHQ0hG_wrapper:focus-within {
  border: 1px solid var(--color-primary);
}

.eHQ0hG_wrapper:focus-within .eHQ0hG_actionButton {
  display: block;
}

.eHQ0hG_wrapper:focus-within input {
  outline: none;
  padding-right: 40px;
}

.eHQ0hG_wrapper.eHQ0hG_error {
  border: 1px solid var(--color-error);
}

.eHQ0hG_errorMessage {
  margin-top: 8px;
}

body.desktop .eHQ0hG_wrapper {
  border: 1px solid var(--color-border);
  background: var(--color-surface);
  border-radius: 6px;
  outline: 3px solid #0000;
}

body.desktop .eHQ0hG_wrapper input {
  font: var(--font-body);
}

body.desktop .eHQ0hG_wrapper input:-webkit-autofill {
  box-shadow: 0 0 0 30px var(--color-surface) inset;
  -webkit-text-fill-color: var(--color-text);
}

body.desktop .eHQ0hG_wrapper .eHQ0hG_actionButton {
  width: 22px;
  height: 22px;
}

body.desktop .eHQ0hG_wrapper.eHQ0hG_error {
  border: 1px solid var(--color-error);
}

body.desktop .eHQ0hG_wrapper:focus-within {
  border-color: var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.nt_K4G_countryCodeSelector {
  color: var(--color-text);
  font: var(--font-body);
  width: auto;
  background: none;
  border: none;
  align-items: center;
  margin-left: 16px;
  margin-right: 4px;
  display: flex;
  position: relative;
}

.nt_K4G_countryCodeSelector > select {
  appearance: none;
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  outline: none;
  font-size: 0;
  position: absolute;
}

.nt_K4G_countryCodeSelector > select option {
  font: var(--font-body);
}

.nt_K4G_countryCodeSelector + input {
  padding-left: 4px;
}

body.mobile .nt_K4G_countryCodeSelector > svg {
  color: var(--color-primary);
  width: 16px;
  height: 16px;
  margin-left: 4px;
}

body.desktop .nt_K4G_countryCodeSelector > svg {
  color: var(--color-icon);
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.CE9sHW_checkbox {
  align-items: center;
  display: inline-flex;
  position: relative;
}

.CE9sHW_checkbox .CE9sHW_icon {
  margin-right: 8px;
}

.CE9sHW_checkbox .CE9sHW_icon > * {
  display: none;
}

.CE9sHW_checkbox .CE9sHW_icon > :nth-child(1) {
  color: var(--color-icon);
}

.CE9sHW_checkbox .CE9sHW_icon > :nth-child(2) {
  color: var(--color-primary);
}

.CE9sHW_checkbox input {
  width: 18px;
  height: 18px;
  opacity: 0;
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.CE9sHW_checkbox input:not(:checked):not(:disabled) ~ .CE9sHW_icon > :nth-child(1), .CE9sHW_checkbox input:checked:not(:disabled) ~ .CE9sHW_icon > :nth-child(2), .CE9sHW_checkbox input:checked:not(:disabled) ~ .CE9sHW_icon > :nth-child(3) {
  display: block;
}

.Umsodq_link {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.Umsodq_link.Umsodq_primary {
  color: var(--color-primary);
  font: var(--font-body-bold);
  text-decoration: none;
}

.Umsodq_link.Umsodq_secondary {
  color: var(--color-caption);
  font: var(--font-body);
  text-decoration: underline;
}

body.desktop .Umsodq_link.Umsodq_primary {
  font: var(--font-body);
}

body.desktop .Umsodq_link.Umsodq_primary:hover {
  text-decoration: underline;
}

body.desktop .Umsodq_link.Umsodq_secondary:hover {
  color: var(--color-primary);
}

.lWVv8a_terms {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  display: flex;
}

.lWVv8a_checkBox {
  fill: var(--color-icon);
  margin-right: 8px;
}

.lWVv8a_content, .lWVv8a_content .lWVv8a_link {
  font: var(--font-caption);
  color: var(--color-caption);
}

.lWVv8a_errorMessage {
  margin-top: 8px;
}

._BtBWW_hidden {
  display: none;
}

._BtBWW_iframe {
  display: block;
}

.FTV6Tq_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.FTV6Tq_form > * {
  width: 100%;
}

.FTV6Tq_form .FTV6Tq_inputField, .FTV6Tq_form .FTV6Tq_terms, .FTV6Tq_form .FTV6Tq_switch {
  margin-bottom: 16px;
}

.FTV6Tq_form .FTV6Tq_switch {
  margin-top: -4px;
  display: block;
}

.FTV6Tq_form .FTV6Tq_formFields {
  margin-bottom: 32px;
}

body.desktop .FTV6Tq_formFields {
  margin-bottom: 8px;
}

.QKOMYq_wrapper {
  flex: 1;
  align-self: stretch;
}

.QKOMYq_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.QKOMYq_title {
  font: var(--font-title);
  color: var(--color-text);
}

.QKOMYq_description {
  color: var(--color-caption);
  margin-bottom: 24px;
}

body.mobile .QKOMYq_container {
  margin-top: 8px;
}

body.mobile .QKOMYq_title {
  margin-bottom: 24px;
}

body.desktop .QKOMYq_container {
  margin-top: 48px;
}

body.desktop .QKOMYq_title {
  font: var(--font-title-medium);
  margin-bottom: 16px;
}

body.desktop .QKOMYq_description {
  font: var(--font-caption);
}

.HL9e4q_passcode {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.HL9e4q_passcode input {
  width: 44px;
  height: 44px;
  text-align: center;
  font: var(--font-body);
  color: var(--color-text);
  caret-color: var(--color-primary);
  border: 1px solid #0000;
  border-radius: 8px;
}

.HL9e4q_passcode input:focus {
  border: 1px solid var(--color-primary);
  outline: none;
}

.HL9e4q_passcode input::placeholder {
  color: var(--color-caption);
}

.HL9e4q_passcode + .HL9e4q_errorMessage {
  margin-top: 8px;
}

body.mobile .HL9e4q_passcode input {
  background: var(--color-layer);
}

body.desktop .HL9e4q_passcode input {
  border: 1px solid var(--color-border);
  background: none;
  outline: 3px solid #0000;
}

body.desktop .HL9e4q_passcode input:focus {
  border: 1px solid var(--color-primary);
  outline-color: var(--color-focused-variant);
}

.dh4rTq_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.dh4rTq_form > * {
  width: 100%;
}

.dh4rTq_form .dh4rTq_inputField {
  margin-bottom: 32px;
}

.dh4rTq_form .dh4rTq_inputField.dh4rTq_withError {
  margin-bottom: 8px;
}

.dh4rTq_form .dh4rTq_link, .dh4rTq_form .dh4rTq_message {
  font: var(--font-caption);
}

.dh4rTq_form .dh4rTq_message > span {
  color: var(--color-primary);
}

body.mobile .dh4rTq_message {
  color: var(--color-text);
}

body.desktop .dh4rTq_message {
  color: var(--color-caption);
}

.fswYpW_wrapper {
  flex: 1;
  align-self: stretch;
}

.fswYpW_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.fswYpW_title {
  font: var(--font-title);
  color: var(--color-text);
  margin-bottom: 4px;
}

.fswYpW_detail {
  color: var(--color-caption);
  margin-bottom: 24px;
}

body.mobile .fswYpW_container {
  margin-top: 8px;
}

body.mobile .fswYpW_detail {
  font: var(--font-body);
}

body.desktop .fswYpW_container {
  margin-top: 48px;
}

body.desktop .fswYpW_title {
  font: var(--font-title-medium);
}

body.desktop .fswYpW_detail {
  font: var(--font-caption);
}

.adSMwW_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.adSMwW_form > * {
  width: 100%;
}

.adSMwW_form .adSMwW_inputField {
  margin-bottom: 16px;
}

.adSMwW_form .adSMwW_formFields {
  margin-bottom: 32px;
}

.adSMwW_form .adSMwW_terms {
  margin-bottom: 16px;
}

body.desktop .adSMwW_formFields {
  margin-bottom: 8px;
}

.HhL-Ma_wrapper {
  flex: 1;
  align-self: stretch;
}

.HhL-Ma_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.HhL-Ma_title {
  font: var(--font-title);
  color: var(--color-text);
}

body.mobile .HhL-Ma_container {
  margin-top: 8px;
}

body.mobile .HhL-Ma_title {
  margin-bottom: 24px;
}

body.desktop .HhL-Ma_container {
  margin-top: 48px;
}

body.desktop .HhL-Ma_title {
  font: var(--font-title-medium);
  margin-bottom: 16px;
}

.m2nksG_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.m2nksG_form > * {
  width: 100%;
}

.m2nksG_form .m2nksG_inputField {
  margin-bottom: 16px;
}

.m2nksG_form .m2nksG_formErrors {
  margin-top: -8px;
  margin-bottom: 16px;
}

.wgSO9W_wrapper {
  flex: 1;
  align-self: stretch;
}

.wgSO9W_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.wgSO9W_title {
  font: var(--font-title);
  color: var(--color-text);
}

body.mobile .wgSO9W_container {
  margin-top: 8px;
}

body.mobile .wgSO9W_title {
  margin-bottom: 24px;
}

body.desktop .wgSO9W_container {
  margin-top: 48px;
}

body.desktop .wgSO9W_title {
  font: var(--font-title-medium);
  margin-bottom: 16px;
}

body.desktop .wgSO9W_description {
  font: var(--font-caption);
}

.YCHB-G_form {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.YCHB-G_form > * {
  width: 100%;
}

.YCHB-G_form .YCHB-G_inputField {
  margin-bottom: 16px;
}

.YCHB-G_form .YCHB-G_formFields {
  margin-bottom: 32px;
}

.YCHB-G_form .YCHB-G_terms {
  margin-bottom: 16px;
}

.YCHB-G_form .YCHB-G_formErrors {
  margin-top: -8px;
  margin-bottom: 16px;
}

body.desktop .YCHB-G_formFields {
  margin-bottom: 8px;
}

.iJtG_q_wrapper {
  flex: 1;
  align-self: stretch;
}

.iJtG_q_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 8px auto 0;
}

.iJtG_q_title {
  font: var(--font-title);
  color: var(--color-text);
}

body.mobile .iJtG_q_container {
  margin-top: 8px;
}

body.mobile .iJtG_q_title {
  margin-bottom: 24px;
}

body.desktop .iJtG_q_container {
  margin-top: 48px;
}

body.desktop .iJtG_q_title {
  font: var(--font-title-medium);
  margin-bottom: 16px;
}

.Uwl8ga_container {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.Uwl8ga_logo {
  height: 60px;
  width: auto;
  object-fit: contain;
  object-position: center;
}

body.mobile .Uwl8ga_container {
  height: 15vh;
  min-height: 92px;
  max-height: 148px;
}

body.mobile .Uwl8ga_logo:not(:last-child) {
  margin-bottom: 8px;
}

body.mobile .Uwl8ga_headline {
  font: var(--font-body);
  color: var(--color-text);
}

body.desktop .Uwl8ga_container {
  min-height: 96px;
}

body.desktop .Uwl8ga_logo:not(:last-child) {
  margin-bottom: 16px;
}

body.desktop .Uwl8ga_headline {
  font: var(--font-title-small);
  color: var(--color-text);
  text-align: center;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.b3_kzq_notification {
  font: var(--font-body);
  color: var(--color-text);
  background: var(--color-surface-variant);
  border: 1px solid var(--color-border-variant);
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  align-items: center;
  margin: 0 auto 8px;
  padding: 12px 16px;
  display: flex;
}

.b3_kzq_notification:focus-visible {
  outline: none;
}

.b3_kzq_icon {
  color: var(--color-outline);
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

.b3_kzq_message {
  flex: 1;
  margin-right: 16px;
}

.b3_kzq_link {
  cursor: pointer;
  text-decoration: underline;
}

body.desktop .b3_kzq_link:hover {
  color: var(--color-primary);
}

.LH6U0W_appNotification {
  max-width: 520px;
  position: absolute;
}

body.mobile .LH6U0W_appNotification {
  top: 24px;
  left: 20px;
  right: 20px;
}

body.desktop .LH6U0W_appNotification {
  width: max-content;
  top: -24px;
  left: 50%;
  transform: translate(-50%, -100%);
}

@media screen and (max-height: 820px) {
  body.desktop .LH6U0W_appNotification {
    top: 24px;
    transform: translate(-50%);
  }
}

.Wo_ieW_wrapper {
  width: 100%;
  max-width: var(--max-width);
  flex-direction: column;
  flex: 1;
  justify-content: normal;
  align-self: stretch;
  align-items: normal;
  margin: 0 auto;
  display: flex;
}

.Wo_ieW_wrapper .Wo_ieW_primarySignIn {
  margin-bottom: 20px;
}

.Wo_ieW_wrapper .Wo_ieW_otherMethodsLink {
  margin-bottom: 24px;
}

.Wo_ieW_wrapper .Wo_ieW_createAccount {
  text-align: center;
  margin-top: 24px;
}

.Wo_ieW_wrapper .Wo_ieW_placeHolder {
  flex: 1;
}

.Wo_ieW_placeholderTop {
  flex: 3;
}

.Wo_ieW_placeholderBottom {
  flex: 5;
}

body.mobile .Wo_ieW_header {
  margin-top: 12px;
  margin-bottom: 48px;
}

body.mobile .Wo_ieW_primarySocial {
  margin-bottom: 32px;
}

body.mobile .Wo_ieW_divider {
  margin-bottom: 20px;
}

body.mobile .Wo_ieW_createAccount {
  padding-bottom: env(safe-area-inset-bottom);
}

body.desktop .Wo_ieW_header {
  margin-bottom: 24px;
}

body.desktop .Wo_ieW_primarySocial {
  margin-bottom: 48px;
}

body.desktop .Wo_ieW_placeHolder {
  flex: 0;
}

body.desktop .Wo_ieW_divider {
  margin-bottom: 16px;
}

.Zfm1pa_divider {
  font: var(--font-body);
  color: var(--color-caption);
  align-items: center;
  display: flex;
}

.Zfm1pa_divider .Zfm1pa_line {
  height: 1px;
  background: var(--color-divider);
  flex: 1;
}

.Zfm1pa_divider .Zfm1pa_line:first-child {
  margin-right: 16px;
}

.Zfm1pa_divider .Zfm1pa_line:last-child {
  margin-left: 16px;
}

.RjiTGa_textLink {
  text-align: center;
}

.RjiTGa_textLink .RjiTGa_signInMethodLink {
  font-size: inherit;
}

.RjiTGa_methodsLinkList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink {
  padding: 0 16px;
  position: relative;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--color-primary);
  opacity: .6;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:first-child {
  padding-left: 0;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child {
  padding-right: 0;
}

.RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child:after {
  content: none;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink {
  padding: 0 20px;
  position: relative;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:after {
  content: "";
  width: 1px;
  height: 12px;
  background-color: var(--color-primary);
  opacity: .6;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:first-child {
  padding-left: 0;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child {
  padding-right: 0;
}

body.desktop .RjiTGa_methodsLinkList .RjiTGa_signInMethodLink:last-child:after {
  content: none;
}

.wltJTG_content {
  background: var(--color-dialogue);
  box-shadow: var(--shadow-2);
  border-radius: var(--radius);
  border: 1px solid var(--color-divider);
}

.wltJTG_content:focus {
  outline: none;
}

.wltJTG_overlay {
  background: none;
  position: fixed;
  inset: 0;
}

.wltJTG_list {
  margin: 0;
  padding: 6px 4px;
}

.uldC3a_item {
  border-radius: var(--radius);
  font: var(--font-body);
  color: var(--color-text);
  cursor: pointer;
  align-items: center;
  padding: 6px 8px;
  list-style: none;
  display: flex;
  overflow: hidden;
}

.uldC3a_item:hover {
  background: var(--color-hover);
}

.dFDs0G_socialDropDown {
  min-width: 208px;
  transform-origin: 12px bottom;
  opacity: 0;
  transition: transform .1s, opacity .1s;
  position: absolute;
  transform: translateY(-100%)scale(0);
}

.dFDs0G_socialLogo {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.ReactModal__Content--after-open.dFDs0G_socialDropDown {
  opacity: 1;
  transform: translateY(-100%)scale(1);
}

.ReactModal__Content--before-close.dFDs0G_socialDropDown {
  opacity: 0;
  transform: translateY(-100%)scale(0);
}

.srqWIG_socialButton {
  background: var(--color-layer);
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.srqWIG_inverse {
  background: var(--color-inverse-social-bg);
}

.srqWIG_icon {
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  overflow: hidden;
}

body.desktop .srqWIG_socialButton:not(.srqWIG_inverse):active {
  background: var(--color-pressed);
}

body.desktop .srqWIG_socialButton:not(.srqWIG_inverse):hover:not(:active) {
  background: var(--color-hover);
}

body.desktop .srqWIG_inverse:active {
  background: var(--color-inverse-social-pressed);
}

body.desktop .srqWIG_inverse:hover:not(:active) {
  background: var(--color-inverse-social-hover);
}

.rOIJdW_socialIconList {
  justify-content: center;
  align-items: center;
  display: flex;
}

.rOIJdW_socialButton {
  margin-right: 32px;
}

.rOIJdW_socialButton:last-child {
  margin-right: 0;
}

.rOIJdW_moreButton {
  width: 40px;
  height: 40px;
  background: var(--color-layer);
  border-radius: 50%;
}

.rOIJdW_moreButton > svg {
  width: 24px;
  height: 24px;
}

.drawer_0edc42 {
  padding-bottom: env(safe-area-inset-bottom);
  outline: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.container_0edc42 {
  background: var(--color-dialogue);
  max-height: 411px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  flex-direction: column;
  justify-content: normal;
  align-items: stretch;
  padding: 20px 20px 0;
  display: flex;
}

.header_0edc42 {
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
}

.header_0edc42 svg {
  color: var(--color-icon);
  width: 20px;
  height: 20px;
}

.content_0edc42 {
  flex: 1;
  padding-bottom: 20px;
  overflow-y: auto;
}

.content_0edc42::-webkit-scrollbar {
  display: none;
}

.ReactModal__Content[role="popup"] {
  transition: transform .3s ease-in-out;
  transform: translateY(100%);
}

.ReactModal__Content--after-open[role="popup"] {
  transform: translateY(0);
}

.ReactModal__Content--before-close[role="popup"] {
  transform: translateY(100%);
}

.PMA8Ha_socialButton {
  justify-content: normal;
  padding: 0 16px;
}

.PMA8Ha_icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  object-position: center;
  overflow: hidden;
}

.PMA8Ha_name {
  flex: 1;
  align-items: center;
  margin-left: -24px;
  display: flex;
  overflow: hidden;
}

.PMA8Ha_name span {
  text-overflow: ellipsis;
  overflow: hidden;
}

.PMA8Ha_name .PMA8Ha_placeHolder {
  flex: 1;
}

.PMA8Ha_name .PMA8Ha_placeHolder:first-child {
  min-width: 40px;
}

._1ILtqG_socialLinkList {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

._1ILtqG_socialLinkButton {
  margin-bottom: 16px;
}

._1ILtqG_expandIcon > svg {
  width: 20px;
  height: 20px;
}

._1ILtqG_expandIcon._1ILtqG_expanded {
  transform: rotate(180deg);
}

.pxDUbq_terms {
  margin-bottom: 16px;
}

.WOKnOG_wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.WOKnOG_connectorContainer {
  flex: 1;
}

.YnZBYa_container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.YnZBYa_container > * {
  width: 100%;
}

.YnZBYa_desc {
  font: var(--font-caption);
  color: var(--color-caption);
  text-align: left;
}

.YnZBYa_desc:not(:first-child) {
  margin-top: 32px;
}

body.mobile .YnZBYa_desc {
  margin-bottom: 8px;
}

body.desktop .YnZBYa_desc {
  margin-bottom: 16px;
}

.Yut7pG_wrapper {
  flex: 1;
  align-self: stretch;
}

.Yut7pG_container {
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
}

body.mobile .Yut7pG_container {
  margin-top: 8px;
}

body.desktop .Yut7pG_container {
  margin-top: 48px;
}

body.desktop .Yut7pG_title {
  font: var(--font-title);
  color: var(--color-text);
  font: var(--font-title-medium);
  margin-bottom: 32px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  text-underline-offset: 2px;
}

input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

:focus-visible {
  outline: solid 1px var(--color-primary);
}

/*# sourceMappingURL=index.49370551.css.map */
